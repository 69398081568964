.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 30px;
  font-family: Arial, sans-serif;
  background-color: #f9fafc;
  border-radius: 10px;
}

/* Contact Info Styling */
.contact-info, .contact-form {
  width: 100%;
  max-width: 600px;
  background-color: #eef9ff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Headings */
.contact-info h2, .contact-form h2 {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

/* Info Section */
.info-section {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.info-section .icon {
  font-size: 22px;
  margin-right: 10px;
}
.info-section .p{
  font-size: 20px;
}

/* Form Styling */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Button Group */
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.button-group button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.button-group button:first-child {
  background-color: #007bff;
  color: white;
  margin-right: 10px;
}

.button-group button:last-child {
  background-color: #ccc;
  color: black;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
  .contact-container {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .contact-info, .contact-form {
    width: 48%;
    max-width: none;
  }
}

@media (max-width: 480px) {
  .contact-info, .contact-form {
    width: 100%;
    padding: 15px;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    margin-bottom: 10px;
  }
}
