/* AboutUs.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.about-container {
  padding: 60px 10%;
  background-color: #f8f9fa;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.section-title {
  font-size: 2.8rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.section-description {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 3rem;
}

.about-section, .mission-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.about-text, .mission-text {
  width: 100%;
  max-width: 600px;
  padding: 2rem;
  text-align: center;
  color: #121212;
}

.about-image, .mission-image {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.about-image img, .mission-image img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.team-section {
  text-align: center;
  margin-top: 50px;
}

.team-title {
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.team-image {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .team-image {
    width: 150px;
    height: 150px;
  }
}

@media screen and (min-width: 1024px) {
  .team-image {
    width: 180px;
    height: 180px;
  }
}

.team-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-text {
  max-width: 500px;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.6;
  color: #555;
}
.p{
  font-size: 1.2rem;
}
