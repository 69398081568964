/* Importing Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  padding-top: 100px; /* Prevent navbar overlap */
}

/* Navbar Styling */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 5%; /* Adjusted padding for better responsiveness */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  background: white;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  height: 70px; /* Reduced height for a sleeker look */
}

/* Glassmorphic effect when scrolled */
.navbar.scrolled {
  background: white;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Logo Styling */
.logo img {
  height: 70px; /* Adjusted for better navbar fit */
  width: auto;
  cursor: pointer;
}

/* Navigation Links */
.nav-links {
  list-style: none;
  display: flex;
  gap: 2.5vw; /* Adjusted gap for more spacing between nav items */
}

.nav-links li {
  position: relative;
}

.nav-links a {
  text-decoration: none;
  color: #b497ea;
  font-size: 1.3rem; /* Ensured readable size for all screens */
  padding: 10px 15px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

/* Hover effect */
.nav-links a:hover {
  color: #ff6600;
}

/* Underline Animation */
.nav-links a::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 50%;
  width: 0;
  height: 2px;
  background: #ff6600;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.nav-links a:hover::after {
  width: 100%;
  left: 0;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  font-size: 30px;
  cursor: pointer;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 20px 5%;
  }

  .nav-links {
    position: absolute;
    top: 80px;
    right: 0;
    background: white;
    width: 100%;
    flex-direction: column;
    display: none;
    text-align: center;
    padding: 15px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    padding: 10px 0;
  }

  .nav-links a {
    font-size: 1.1rem; /* Adjusted size for mobile screens */
  }

  .hamburger {
    display: block;
  }
}
