/* Products.css (or combine with Services.css if you prefer) */

.products-container {
  padding: 10vw; /* Increased padding for better spacing */
  background-color: #d9e2f0;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
}

.products-title {
  font-size: 3vw; /* Relative font size based on viewport */
  margin-bottom: 3vw; /* Adjust margin for better spacing */
  font-weight: bold;
  color: #0f0e0e;
}

.products-grid {
  display: flex;
  justify-content: center;
  gap: 5vw; /* Use viewport-relative spacing */
  flex-wrap: wrap;
}

.product-card {
  background-color: #cad8f0;
  border-radius: 10px;
  padding: 5vw;
  width: 30vw; /* Relative width */
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.product-card:hover {
  background-color: #a8b7e3;
  transform: scale(1.05);
}

.product-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-card h2 {
  color: #0f0e0e;
  font-size: 2vw; /* Adjusted font size */
  margin-bottom: 2vw; /* Adjusted margin */
}

.product-card p {
  color: #101011;
  font-size: 1vw;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .products-title {
    font-size: 6vw;
  }

  .product-card {
    width: 40vw; /* Adjust card width for smaller screens */
    padding: 6vw;
  }

  .product-card h2 {
    font-size: 5vw;
  }

  .product-card p {
    font-size: 3vw;
  }
}

@media (max-width: 500px) {
  .products-title {
    font-size: 8vw;
  }

  .product-card {
    width: 80vw; /* Full-width cards on small screens */
    padding: 8vw;
  }

  .product-card h2 {
    font-size: 7vw;
  }

  .product-card p {
    font-size: 4vw;
  }
}
