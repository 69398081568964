/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
}

.services-container {
  padding: 60px 10%;
  background: #f9fafc;
  text-align: center;
}

.services-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #121212;
  margin-bottom: 2rem;
}

.services-subtitle {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 3rem;
}

/* Grid Layout */
.services-grid {
  display: flex; /* Using flexbox similar to product layout */
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

/* Service Card */
.service-card {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  width: 100%;
  max-width: 300px; /* Set a max-width to keep cards consistent */
}

.service-card:hover {
  transform: scale(1.05);
}

/* Service Card Content */
.service-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

/* Heading */
.service-card h2 {
  color: #121212;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

/* Description */
.service-card p {
  color: #555;
  font-size: 1rem;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-container {
    padding: 50px 5%;
  }

  .services-title {
    font-size: 2rem;
  }

  .services-subtitle {
    font-size: 1rem;
  }

  .services-grid {
    gap: 15px;
  }
}

@media (max-width: 500px) {
  .services-title {
    font-size: 1.8rem;
  }

  .services-subtitle {
    font-size: 1rem;
  }

  .service-card {
    padding: 1.5rem;
  }

  .service-icon {
    width: 40px;
    height: 40px;
  }
}
