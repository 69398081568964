/* home.css */
.home-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* Background Video */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Home Content */
.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Headings */
h1 {
  font-size: 5vw;
  font-weight: bold;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
}

p {
  font-size: 2vw;
  margin-top: 10px;
}

/* Call to Action Button */
.cta-button {
  background-color: #ff6600;
  color: white;
  padding: 1.5vw 3vw;
  border: none;
  cursor: pointer;
  font-size: 2vw;
  border-radius: 5px;
  margin-top: 5vh;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #e55e00;
}

@media (max-width: 768px) {
  .content {
    padding: 10vw;
  }
  h1 {
    font-size: 12vw;
  }
  p {
    font-size: 5vw;
  }
  .cta-button {
    font-size: 5vw;
    padding: 2vw 4vw;
  }
}

@media (max-width: 500px) {
  .content {
    padding: 12vw;
  }
  h1 {
    font-size: 15vw;
  }
  p {
    font-size: 6vw;
  }
  .cta-button {
    font-size: 6vw;
    padding: 3vw 5vw;
  }
}
