/* footer.css */
.footer-container {
  position: relative;
  background-color: #181818;
  color: white;
  padding: 30px 0; /* Adjusted padding */
  text-align: center;
  width: 100%;
  bottom: 0;
  margin-top: 50px; /* Adjusted margin */
  border-radius: 10px; /* Keep the border-radius */
  box-shadow: 0px -2px 5px rgba(0,0,0,0.2); /* Added a subtle shadow */
}

.footer-content {
  max-width: 960px; /* Adjusted max-width */
  margin: 0 auto;
  padding: 0 20px; /* Adjusted padding */
}

.footer-content p {
  margin: 15px 0; /* Adjusted margin */
  font-size: 16px; /* Fixed font size */
  opacity: 0.8; /* Slightly reduced opacity for the text*/
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 30px; /* Adjusted gap between social icons */
  margin-top: 20px; /* Adjusted margin */
}

.social-links a {
  color: white;
  text-decoration: none;
  display: inline-flex; /* Ensures proper icon alignment */
  align-items: center;  /* Centers the icon vertically */
}

.social-icon { /* Style for the social icons */
  font-size: 24px; /* Adjusted icon size */
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.social-links a:hover .social-icon { /* Hover effect for icons */
  color: #ff6200;
}

/* Removed media query for simplicity. Adjust as needed */